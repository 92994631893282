import Default from './default'
import es6ClassBindAll from 'es6-class-bind-all'
import select from 'dom-select'
import { gsap } from "gsap"
import event from 'dom-event'
import videojs from 'video.js'
import config from '../config'
import * as THREE from 'three';
import * as dat from 'dat.gui';


class Home extends Default {
	constructor(options) {
		super('home')
        const defaults = {
            width: window.innerWidth,
            height: window.innerHeight
        };

        Object.assign(this, options, defaults);
	}

    mounted() {

        this.cache()
        this.init()

    }

    cache() {


    }

    init() {

        this.randomDesign()
        
    }

    randomDesign() {

      const number = this.getRandomInt(2)
      select(`.design[data-number="${number}"]`).classList.add('select')
      if(number == 0) {

        this.newOne()

      } else {

        select('html').style.backgroundColor = '#1dfc02'
        this.newTwo()

      }

    }

    getRandomInt(max) {
  
      return Math.floor(Math.random() * max)

    }

    newOne() {

      // this.initWidth()
      this.addEvents('on')
      config.isTwo && this.setWidthMarquee()


    }

    newTwo() {

      this.eventCursor()
      config.isTouch && this.initMobileMovement()

    }


    ////////// DESIGN TWO //////////

    initMobileMovement() {

        // bouton de validation pour iphone + lancement de l'écouteur d'orientation sur mobile
      if (typeof DeviceOrientationEvent !== 'undefined' && typeof DeviceOrientationEvent.requestPermission === 'function') {
  
        event['on'](select('.validation'), 'click', () => {
      
        DeviceOrientationEvent.requestPermission()
  
        .then(permissionState => {
  
          select('.validation').remove()
          if (permissionState === 'granted') {
            window.addEventListener('deviceorientation', handleOrientation)
          }
  
        })
  
        .catch(console.error)
  
      })
  
      } else {
  
        select('.validation').remove()
        window.addEventListener("deviceorientation", handleOrientation, true);
  
      }

      const ball = select('.ball')
      var maxX = ( window.innerWidth * 1.5)  - ball.clientWidth;
      var maxY = ( window.innerHeight * 1.5 ) - ball.clientHeight;
    
    
      function handleOrientation(event) {
    
        select('.ball').style.opacity = 1
        console.log(event.gamma, event.beta )
        let x = event.gamma // In degree in the range [-90,90]
        let y = event.beta // In degree in the range [-180,180]

        
        ball.style.opacity = 1

        // ball.textContent  += `beta : ${x}\n`;
        // ball.textContent += `gamma: ${y}\n`;

        // Because we don't want to have the device upside down
        // We constrain the x value to the range [-90,90]
        if (x >  90) { x =  90};
        if (x < -90) { x = -90};

        // To make computation easier we shift the range of
        // x and y to [0,180]
        x += 90;
        y += 90;

        // 10 is half the size of the ball
        // It center the positioning point to the center of the ball
        ball.style.top  = (maxY*y/180 - 250) + "px";
        ball.style.left = (maxX*x/180 - 100) + "px";

      }
        
    }

    eventCursor() {

      !config.isOne && event['on'](window, 'mousemove', this.cursorHandler)

    }

    cursorHandler(e) {

      const ball = select('.ball')
      ball.style.opacity = 1

      const bullHalfHeight = ball.offsetHeight / 2
      const bullHalfWidth = ball.offsetWidth / 2

      // let left
      // if (e.clientX < window.innerWidth - ball.offsetWidth)
      // {left = e.clientX - bullHalfHeight} else {left = window.innerWidth - ball.offsetWidth}

      // console.log(e.clientX< window.innerWidth - ball.offsetWidth )
      ball.style.left = `${e.clientX - bullHalfHeight}px`
      ball.style.top = `${e.clientY - bullHalfWidth}px`

    }

    

    ////////// DESIGN ONE //////////

    initWidth() {

        const logoWidth = select('.title_site').getBoundingClientRect().width
        const widthWindow = window.innerWidth

        select('.wrapper').style.width = `${ logoWidth + widthWindow }px`

    }

    addEvents(type) {

      this.opacityCount = 1
      this.opacityCountNext = 0
      this.gradients = [...document.querySelectorAll('.gradient')]
      this.gradientSelect = 3
      this.nextGradient = 0
      this.oldScroll = select('.container').getBoundingClientRect().left

      let element = select('.container')
      !config.isTwo && event[type](element, 'wheel', this.transformScroll)
      config.isTwo
      ?event[type](window, 'scroll', this.handlerScroll)
      :event[type](element, 'scroll', this.handlerScroll)

    }

    transformScroll(event) {

      if (!event.deltaY) {
        return
      }
      event.preventDefault()
      event.currentTarget.scrollLeft += (event.deltaY + event.deltaX)

    }

    handlerScroll(e) {

      const scroll = select('.container').getBoundingClientRect().left

      if (scroll <= this.oldScroll) {

        console.log(
          'opacityCount', this.opacityCount,
          'opacityCountNext', this.opacityCountNext,
          'gradientSelect', this.gradientSelect,
          'nextGradient', this.nextGradient
        )

        if (this.opacityCount > 0.01) {

          this.opacityCount -= 0.01
          this.opacityCountNext += 0.01
          this.gradients[this.gradientSelect].style.opacity = this.opacityCount
          this.gradients[this.nextGradient].style.opacity = this.opacityCountNext
  
        } else {
  
          this.gradients[this.gradientSelect].style.opacity = 0
          this.gradients[this.nextGradient].style.opacity = 1
          this.opacityCount = 1
          this.opacityCountNext = 0
          this.gradientSelect = this.nextGradient
          this.nextGradient + 1 < this.gradients.length
          ? this.nextGradient += 1
          : this.nextGradient = 0
  
        }

      } else {

        if (this.opacityCountNext > 0.01) {

          this.opacityCount += 0.01
          this.opacityCountNext -= 0.01
          this.gradients[this.gradientSelect].style.opacity = this.opacityCount
          this.gradients[this.nextGradient].style.opacity = this.opacityCountNext

        } else {

          this.gradients[this.gradientSelect].style.opacity = 1
          this.gradients[this.nextGradient].style.opacity = 0
          this.opacityCount = 0
          this.opacityCountNext = 1
          this.nextGradient = this.gradientSelect
          this.gradientSelect - 1 >= 0
          ? this.gradientSelect -= 1
          : this.gradientSelect = this.gradients.length - 1
          
        }

      }

    }
  
    setWidthMarquee() {
  
      const marquee = select('.marquee')

      let width = select('.scroll span').offsetWidth 
      const widthW = window.innerWidth
      // width -= widthW
      marquee.style.setProperty('--width', `${-width}px`)

      const scrolls = document.querySelectorAll('.scroll')
      scrolls.forEach((scroll) => {

        scroll.style.setProperty('-webkit-animation', 'webkitmarqueeMobile 25s linear infinite')

      })
  
    }

    onResize() {

      config.isTwo && this.setWidthMarquee()
      // this.initWidth()

    }
}

export default Home