import select from 'dom-select'
import { gsap } from "gsap"
import event from 'dom-event'
import config from '../config'
import bind from 'es6-class-bind-all'

class Nav {

	constructor() {
		bind(this)
		setTimeout(() => {
			this.cache()
			this.init()
		},400)

	}

	cache() {

        this.navigation = select('.nav_mob')
        this.burger = select('.burger')
        this.items = document.querySelectorAll(".b-nav li")
        // this.itemsNav = [...this.navigation.querySelectorAll('.nav_item')]

	}

	init() {

		this.initBurger()

	}

	// addEventsNav(type){

    //     config.isOne && event[type](this.burger, 'click', this.toggleNavigation)

    //     // this.itemsNav.forEach((item) => {
    //     //     config.isTwo && event[type](item, 'click', this.closeNavigation)
    //     // })

	// }

    // closeNavigation() {

    //     this.navigation.classList.remove('open')

    //     const tl = gsap.timeline()
    //     .staggerTo(this.itemsNav, 0.1, {
    //         opacity: 0,
    //         rotateY: 100,
    //         ease: 'back.inOut(1)',
    //         x: 10,
    //     }, 0.1)

    // }

    // openNavigation() {

    //     this.burger.classList.add('open')

    //     const tl = gsap.timeline()
    //     .staggerTo(this.itemsNav, 0.2, {
    //         opacity: 1,
    //         rotateY: 0,
    //         ease: "back.inOut(2)",
    //         x: 0,
    //     }, 0.1, '+=0.2')

    //     this.navigation.classList.add('open')

    // }

    // toggleNavigation() {

    //     const open = this.navigation.classList.contains('open')

    //     if( open ) {

    //         this.closeNavigation()

    //     } else {

    //         this.openNavigation()

    //     }

    // }

    initBurger() {

        const e = document.getElementsByClassName("b-menu")[0];
        event.on(e, "click", this.toggleClasses),
            this.items.forEach((e) => {
                event.on(e, "click", this.toggleClasses);
            });

    }

    toggleClasses() {

        const e = select(".layout_nav");
        if (
            ([e, document.getElementsByClassName("b-container")[0], document.getElementsByClassName("b-nav")[0], select(".mobile.menu_language")].forEach((e) => {
                e.classList.toggle("open");
            }),
            e.classList.contains("open"))
        ) {
            gsap.timeline().staggerTo(this.items, 0.3, { x: 0, opacity: 1 }, 0.1);
        } else {
            gsap.timeline().staggerTo(this.items, 0.2, { x: -250, opacity: 0 }, 0);
        }
    }
}

export default Nav