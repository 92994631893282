import Default from './default'
import es6ClassBindAll from 'es6-class-bind-all'
import select from 'dom-select'
import { gsap } from "gsap"
import event from 'dom-event'
import videojs from 'video.js'
import config from '../config'


class Press extends Default {
	constructor() {
		super('press')
	}

    mounted() {

        this.cache()
        this.init()

    }

    cache() {

    }


    init() {
        
        this.addEvents('on')
        this.initText()

    }

    addEvents(type) {

        event[type](select('.index_button'), 'click', this.goToHomeList)

    }

    goToHomeList() {

        localStorage.setItem('index', true )
        window.location.href = "http://pandoragraessl.julienprivat.com/"

    }

    onResize() {

        // this.initcarousel()

    }
}

export default Press