import Default from './default'
import es6ClassBindAll from 'es6-class-bind-all'
import select from 'dom-select'
import { gsap } from "gsap"
import event from 'dom-event'
import videojs from 'video.js'
import config from '../config'


class Projet extends Default {
	constructor() {
		super('projet')
	}

    mounted() {

        this.cache()
        this.init()

    }

    cache() {

        config.isOne && this.initTitleMobile()
        this.initVideo()

    }


    init() {
        
        this.addEvents('on')
        this.initText()

    }

    addEvents(type) {

        event[type](select('.index_button'), 'click', this.goToHomeList)

    }

    goToHomeList() {

        localStorage.setItem('index', true )
        window.location.href = "http://affaireasuivre.studio/"

    }

    initTitleMobile() {

        const titles = [...document.querySelectorAll('.first_item')]
        titles.forEach((title) => {
            let text = title.querySelector('.title_text').textContent
            const length = text.length
            if ( length > 38 ) { title.querySelector('.title_text').textContent = `${text.substring(0,38)}...` }
        })

    }

    initVideo() {

        const videos = [...document.querySelectorAll('video')]
        videos.forEach((video) => {

            var options = {};

            if (!config.isOne) {

                const player = videojs(video, options, function onPlayerReady() {
                    videojs.log('Your player is ready!');
                  
                    this.on('mouseover',() => {
    
                        this.play()
    
                    })
    
                    this.on('mouseleave',() => {
    
                        this.pause()
    
                    })
                  
                    // How about an event listener?
                    this.on('ended', () => {
                      videojs.log('Awww...over so soon?!');
                    })
                  })

            } else {

                const player = videojs(video, options, function onPlayerReady() {
                    videojs.log('Your player is ready mob !');
                  
                    this.on('touchstart', function() {
                        if (player.paused()) {
                          player.play();
                        } else {
                          player.pause();
                        }
                      });
                  
                    // How about an event listener?
                    this.on('ended', () => {
                      videojs.log('Awww...over so soon?!');
                    })
                  })

            }

        })

    }

    initText() {

        const paras = [...document.querySelectorAll('p')]
        paras.forEach((para, key) => {

            const quidPair = key%2 == 0
            if(!quidPair) { para.style.paddingLeft = '70px' }

        })

    }

    initcarousel() {

        const images = [...document.querySelectorAll('img')]
        const videos = [...document.querySelectorAll('div[aria-label="Video Player"]')]
        const content = images.concat(videos)
        const wrapper = document.querySelector('.wrapper_images')
        const description = document.querySelector('.description')
        const firstMedia = content[0].getBoundingClientRect().width / 2

        let count 
        description 
        ? count = window.innerWidth - 110
        : count = ( window.innerWidth / 2 ) - firstMedia - 33

        const length = content.length
        const lastMedia = content[length - 1]
        const middleLastMedia = lastMedia.getBoundingClientRect().width / 2

        content.forEach((media) => {

        
            const width = media.getBoundingClientRect().width
            count = count + width + 66

        })

        wrapper.style.width = `${count}px`
        wrapper.style.marginRight = `${( window.innerWidth / 2 ) - middleLastMedia - 33}px`

    }

    onResize() {

        // this.initcarousel()

    }
}

export default Projet